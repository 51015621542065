<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <cmsHeader/>
    <Toast position="top-right" />

  <div class="container">

<div class="formcontainer">
  <h1>Resources : <!-- <?php echo isset($_GET['id']) ? 'Edit' : 'Add'; ?> --></h1>
  <p>Select <b>Active</b> to show your post on the <b>Resources</b> page or select <b>Pending</b> to temporarily hide the post.
  Enter the information below and click the <b>Submit</b> button to submit your post.</p>

  <form method="post" id="detailsForm" class="form-horizontal">
    <input type="hidden" name="id" value="<?=$id?>" />
    
    <div class="form-group form-group-sm">
      <label class="col-xs-12 col-sm-2 control-label" for="status">Status <span class="required">*</span></label>
      

        <div class="col-xs-12 col-sm-10">
          <label class="radio-inline statusactive">
          <input type="radio" name="status" value="active" /> Active
        </label>
        <label class="radio-inline statuspending">
            <input type="radio" name="status" value="pending" /> Pending
          </label>
        </div>
    </div>
    
    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="title"> Title <span class="required">*</span> : </label>
      <div class="col-xs-12 col-sm-8">
        <InputText name="Title" class="formwidthmedium form-control" v-model="resource.title" size="60" />
        </div>
    </div>
      
    <div class="form-group form-group-sm">
      <label class="col-xs-12 col-sm-2 control-label" for="date">Date <span class="required">*</span></label>
        
        <div class="col-sm-2">
          <Calendar
            name="Date"
            v-model="resource.date"
            :showIcon="true"
          />
      </div>
    </div>
    <!-- 
    <script type="text/javascript">
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    $(function() {
      $('#date').datetimepicker({
        format: "YYYY-MM-DD"
      });
      $('#date').on('dp.change', function(){
        $('#detailsForm').formValidation('revalidateField', 'date');
      });
    });
    </script>
    -->
    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="post">Post <span class="required">*</span>
          <div class="tooltipposition btn-group-vertical">
            <button type="button" class="btn-default btn-sm tooltip-btn new-tooltip" data-toggle="tooltip" data-placement="bottom" title="Use the editor to format text (bold, headings, lists, etc.) To add a link to a website or email address, click the Link button on the header and select the appropriate. Do not use this textbox to post youtube videos. Use the Video field below and write a short paragraph about the video here!"><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></button>
          </div>
        </label>

        <div class="col-xs-12 col-sm-10">
          <Editor
            
            v-model="resource.post"
            editorStyle="height: 320px"
          />
          <!-- <textarea class="form-control" name="post"><?=$post?></textarea> -->
        </div>
    </div>
    <!-- 
    <script type="text/javascript">
    //ckeditor
    $('[name="post"]').ckeditor().editor.on('change', function() {
      $('#detailsForm').formValidation('revalidateField', 'post');
    });
    </script>
    -->
    <div class="form-group form-group-sm">
      <label class="col-xs-12 col-sm-2 control-label" for="formGroupInputSmall">Photos</label>
      <div class="required">&nbsp;&nbsp;</div>
      <div class="col-xs-12 col-sm-10">
        <div class="text_container">
                    <p><span class="glyphicon glyphicon-chevron-down"></span> <b>Instructions</b></p>
            <div>
                <p>
                  <b>Step 1: Add images to the screen.</b>
              <ol>					
                
                <li> <b>To add images to the screen click the Add Files button.</b> Hold down ctrl to select multiple photos at a time.
              Another way of adding photos is to drag them from your desktop onto the 
              <b>Drag files here</b> part of the uploader. </li> 
              <li> <b>Once you have added all of your photos then click the
              Start Upload button</b>.</li>
              </ol>	<BR/>
                <b>Step 2: Store images in the database</b> 
                <ol>
              <li>To store images in the database click the <b>SUBMIT</b> button at the bottom of the page.</li> 
              </ol><br/>
              
              After you have added your photos, click on the Title to add the photo <b>Caption</b>.
            </p>
            </div>
        </div>
        <div id="uploader"></div>
      </div>
    </div>
    <!-- 
    <script type="text/javascript">
    //plupload
    // Initialize the widget when the DOM is ready
    $(function() {
        $("#uploader").plupload({
            runtimes : 'html5,html4',
            url : "../plupload/examples/upload-blog.php",
            max_file_size : '2mb',
            chunk_size: '1mb',
            resize : {
                height : 200,
                width: 300,
                crop: true,
                quality : 90,
            },
            filters : [
                {title : "Image files", extensions : "jpg,gif,png"}
            ],
            rename: true,
            sortable: true,
            dragdrop: true,
            views: {
                list: true,
                thumbs: true, 
                active: 'thumbs'
            }
        });
    });
    </script>
    -->

    <!-- <?php if(isset($_GET['id'])){ ?> -->

    <div class="form-group" id="end">
      <!-- <?php
      $imgQry = "SELECT * FROM blog_images WHERE blog_id = $id ORDER BY position";
      $imgRes = $db->query($imgQry);

      $imgCnt = $imgRes->num_rows;
      
      if($imgCnt > 0){ ?> -->
        <label for="" class="col-xs-12 col-sm-2 control-label">Photo Edit</label>
        <div class="col-xs-12 col-sm-10"><div class="smallp" style="width:100%;">
          To change the order of your images you can drag and drop the images in the order you would like them to display. To delete an image, click the Delete checkbox above the photo you would like to be deleted. *Note: You will only see your changes after you have clicked the Submit button.
        </div>

        <ul id="sortable" class="sortable ui-sortable">
          <!-- <?php
          $x = 1;
          while ($imgRow = $imgRes->fetch_array()) {
              $filename = $imgRow['filename'];
              $caption = $imgRow['caption'];
              $id = $imgRow['blog_image_id']
            ?> -->
            <li class="ui-state-default" id="item_<?PHP echo $x; ?>" style="width: 175px;">
              <div style="height: 302px; overflow: hidden; padding: 3px;">
                <div align="left" style="float:left;margin-top:20px;"><input type="checkbox" name="deleteImages[]" value="<?PHP echo $filename; ?>">Delete</div>
                <br />
                <span class="extra-blogpicture blogpicture" style="background-image: url('<?=ROOT?>user-content/blog/<?=$filename?>')"></span>
                <br />
                <!-- <div class="formsubheading">Caption</div> -->
                <!-- <textarea style="z-index: 1; font-size: 13px; width: 148px;" maxlength="64" rows="4" name="caption_<?php echo $id; ?>"><?php echo $caption; ?></textarea> -->
                <input type="hidden" name="imageOrder_<?PHP echo $x; ?>" value="<?PHP echo $id; ?>" />
              </div>
            </li>
          <!-- <?php
          $x++;
          }
          ?> -->
        </ul>
      </div>
      <!-- <?PHP } ?> -->
    </div>

    <!-- <?php } ?> -->
    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="ytLink">Video 
          <div class="tooltipposition btn-group-vertical">
              <button type="button" class="btn-default btn-sm tooltip-btn new-tooltip" data-toggle="tooltip" data-placement="bottom" title="Copy and paste a Youtube URL into this field."><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></button>
          </div>
      </label>
      <div class="col-xs-12 col-sm-8">
        <InputText class="formwidthmedium form-control" name="ytLink" v-model="resource.ytLink" maxlength="200" />
      </div>
    </div>

    <div class="form-group form-group-sm">
        <label class="col-xs-12 col-sm-2 control-label" for="scEmbed">Podcast Embed 
          <div class="tooltipposition btn-group-vertical">
              <button type="button" class="btn-default btn-sm tooltip-btn new-tooltip" data-toggle="tooltip" data-placement="bottom" title="Copy and paste a soundcloud embed code into this field. To find this go to the podcast soundcloud homepage. Click SHARE under the episode you would like to share. Click EMBED at the top of the popup. Copy the code provided in the textbox."><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></button>
          </div>
      </label>
      <div class="col-xs-12 col-sm-8">
        <Textarea class="formwidthmedium form-control" name="scEmbed" v-model="resource.scEmbed" maxlength="2000" :autoResize="true" />
      </div>
    </div>

    <div class="form-group">
      <label>&nbsp;</label>
      <div class="formwidthmedium"><center><button type="submit" class="btn-orange btn btn-default">Submit</button></center></div>
    </div>
  </form>
</div>

</div>

    <cmsFooter/>
  </div>
</template>

<script>
import { ref } from 'vue'
// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'

// Primevue
import Toast from 'primevue/toast'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import Editor from 'primevue/editor'
import Textarea from 'primevue/textarea'

export default {
  name: '',
  components: {
    cmsHeader, 
    cmsFooter, 
    Toast,
    InputText,
    Calendar,
    Editor,
    Textarea,
  },
  setup() {
    const resource = ref({
      title: null,
      date: null,
      post: null,
      ytLink: null,
      scEmbed: null
    })

    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
      resource,
      environment
    }
  },
}
</script>

<style lang="scss" scoped>

</style>